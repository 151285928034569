<template>
  <div class="contenBox">
    <!-- 第一部分 -->
    <div class="mainBody">
    <!-- IP -->
    <div class="ipCopyBox clearfix">
      <div class="ipcontentBox">
        <el-input
          v-model="iPinput"
          :disabled="true">
        </el-input>
      </div>
      <div class="ipBntBox">
        <el-button type="warning" @click="copyIP">复制</el-button>
      </div>
    </div>
    <!-- 产品链接 -->
    <div class="ipCopyBox clearfix">
      <div class="ipcontentBox">
        <el-input
          placeholder="请输入产品链接"
          v-model="cPinput"
          clearable>
        </el-input>
      </div>
      <div class="ipBntBox">
        <el-button type="warning" @click="copyCP">复制</el-button>
      </div>
    </div>
  </div>
  <!-- 第二部分 随机生成注册信息 -->
  <div class="mainBody">
    <!-- 用户名 -->
    <div class="infoText">用户名：</div>
    <div class="infoCopyBox clearfix">
      <div class="infocontentBox">
        <el-input
          v-model="usernameinput"
          :disabled="true">
        </el-input>
      </div>
      <div class="infoBntBox">
        <el-button type="warning" @click="copyUsername">复制用户名</el-button>
      </div>
    </div>
    <!-- 电话号码 -->
    <div class="infoText">电话号码：</div>
    <div class="infoCopyBox clearfix">
      <div class="infocontentBox">
        <el-input
          v-model="phoneinput"
          :disabled="true">
        </el-input>
      </div>
      <div class="infoBntBox">
        <el-button type="warning" @click="copyPhone">复制号码</el-button>
      </div>
    </div>
    <!-- 密码 -->
    <div class="infoText">密码：</div>
    <div class="infoCopyBox clearfix">
      <div class="infocontentBox">
        <el-input
          v-model="passwordinput"
          :disabled="true">
        </el-input>
      </div>
      <div class="infoBntBox">
        <el-button type="warning" @click="copyPassword">复制密码</el-button>
      </div>
    </div>
    <!-- 名字 -->
    <div class="infoText">人名：</div>
    <div class="infoCopyBox clearfix">
      <div class="infocontentBox">
        <el-input
          v-model="nameinput"
          :disabled="true">
        </el-input>
      </div>
      <div class="infoBntBox">
        <el-button type="warning" @click="copyName">复制人名</el-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import nameData from '../name.json';
export default {
  name: 'HelloWorld',
  data() {
    return {
      iPinput: 'https://whoer.net/zh',
      cPinput: '',
      randomPhoneHead:['119','849','719','229','889','619','439','229','159','989','219','759','379',],
      phoneinput:'',
      passwordinput:'',
      nameinput:'',
      usernameinput:''
    }
  },
  methods:{
    // 复制 查IP
    copyIP(){
      navigator.clipboard.writeText(this.iPinput)
          .then(() => {
            this.$message({
                message: '复制成功',
                type: 'success'
              });
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      },
      // 复制 产品链接
    copyCP(){
      navigator.clipboard.writeText(this.cPinput)
          .then(() => {
            this.$message({
                message: '复制成功',
                type: 'success'
              });
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      },
      // 随机号码
      randomPhone(){
        // 生成号码开头3位
        let x = 12;
        let y = 0;
        let res = parseInt(Math.random() * (x - y + 1) + y );
        // 生成号码后面8位
        let suffix = '';
        for (let i = 0; i < 8; i++) {
          suffix += Math.floor(Math.random() * 10);
        }
        this.phoneinput = this.randomPhoneHead[res]+suffix
      },
      // 随机密码
      randomPassword(){
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let passwordLength = 8;
        while (passwordLength < 10) {
          let password = '';
          for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            password += chars.charAt(randomIndex);
          }
          if (/\d/.test(password)) {
            this.passwordinput = password;
            break;
          }
          passwordLength++;
        }
      },
      // 随机人名
      randomName(){
        let x = 216449;
        let y = 0;
        let res = parseInt(Math.random() * (x - y + 1) + y );
        this.nameinput = nameData[0][res].name
      },
      // 随机用户名
      randomUsernameinput(){
        let x = 216449;
        let y = 0;
        let res = parseInt(Math.random() * (x - y + 1) + y );

        this.usernameinput = nameData[0][res].name.split(' ')[0]
      },
      // 复制 用户名
      copyUsername(){
        navigator.clipboard.writeText(this.usernameinput)
          .then(() => {
              this.randomUsernameinput();  //重新刷新
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      },
      // 复制 电话号码
      copyPhone(){
        navigator.clipboard.writeText(this.phoneinput)
          .then(() => {
              this.randomPhone(); //重新刷新
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      },
      // 复制 密码
      copyPassword(){
        navigator.clipboard.writeText(this.passwordinput)
          .then(() => {
              this.randomPassword();  //重新刷新
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      },
      // 复制 人名
      copyName(){
        navigator.clipboard.writeText(this.nameinput)
          .then(() => {
              this.randomName();  //重新刷新
          })
          .catch((err) => {
            // 复制失败
            this.$message.error('复制失败',err);
          });
      }
    }
  }
</script>


<style scoped>
.contenBox{
  width: 100%;
  height: 100%;
}
.mainBody{
  width: 80%;
  height: 100%;
  padding: 10px;
  margin: 18px auto;
  background-color: #a394e2;
  border-radius: 10px;
}
.ipCopyBox{
  width: 98%;
  margin: 18px auto;
  height: 40px;
}
.ipcontentBox{
  width: 60%;
  height: 40px;
  float: left;
}
.ipBntBox{
  width: 20%;
  height: 40px;
  float: left;
  margin-left: 10px;
}
/* 信息 */
.infoCopyBox{
  width: 98%;
  margin: 0 auto;
  height: 40px;
}
.infoText{
  width: 98%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 1em;
  font-weight: 900;
}
.infocontentBox{
  width: 50%;
  height: 40px;
  float: left;
}
.infoBntBox{
  margin-left: 10px;
  width: 20%;
  height: 40px;
  float: left;
}
</style>
